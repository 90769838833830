<template>
  <div class="forgotPwd__wrapper">
    <div class="forgotPwd__box">
      <div class="row flex-column">
        <div class="col-12 d-flex flex-column">
          <label class="mb10">Password:</label>
          <input type="password" v-model="form.password" />
          <span
            v-if="$v.form.password.$error"
            class="error"
          >Password must be at least 6 characters in length</span>
        </div>
      </div>

      <div class="row flex-column">
        <div class="col-12 d-flex flex-column">
          <label class="mb10">Confirm Password:</label>
          <input type="password" v-model="form.c_password" />
          <span
            v-if="$v.form.c_password.$error"
            class="error"
          >Confirm Password must be same as password</span>
        </div>
      </div>
      <button
        class="buttonload btn btn--blue w100"
        style="pointer-events: none"
        v-if="isSubmitting"
      >submitting ...</button>
      <button v-if="!isSubmitting" @click.prevent="onSubmit()">Submit</button>
    </div>
  </div>
</template>
<script>
import { resetPassword } from "@/api/user";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        hash: "",
        password: "",
        c_password: ""
      },
      isSubmitting: false
    };
  },
  created() {
    this.hash = this.$route.params.hash;
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.isSubmitting = true;
      resetPassword({
        token: this.hash,
        password: this.form.password,
        c_password: this.form.c_password
      })
        .then(res => {
          this.$notify({
            group: "notify",
            type: "success",
            text: res.data.message
          });
          this.isSubmitting = false;
          this.$router.push({ name: "dashBoard" });
        })
        .catch(err => {
          this.$notify({
            group: "notify",
            type: "error",
            text: err.response.data.message
          });
          this.isSubmitting = false;
        });
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      c_password: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
<style scoped>
.forgotPwd__wrapper {
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-content: center;
}

.forgotPwd__box {
  min-width: 350px;
  max-width: fit-content;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.forgotPwd__box input {
  background-color: #f2f5f9;
  border-radius: 3px;
  padding: 1rem;
  border: none;
}

.forgotPwd__box button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  width: 100%;
  background-color: rgba(38, 140, 171, 1);
  color: white;
  box-shadow: 0 2px 0 rgba(13, 80, 101, 1);
  text-align: center;
  vertical-align: middle;
  border: none;
  padding: 17px 18px 16px;
  line-height: 19px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
}
.forgotPwd__box button:hover {
  background-color: rgba(38, 140, 171, 0.9);
}

.forgotPwd__box .error {
  display: block;
  font-size: 13px;
  color: tomato;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}

.forgotPwd__box .row {
  margin-bottom: 1.5rem;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>